<template>
  <div
    class="row mb-2"
  >
    <div 
      class="col-4"
    >
      <dropdown
        label="Gebruiker toevoegen"
        :options="userOptions" 
        @update:value="addUser"
      />
    </div>
  </div>

  <loading-spinner 
    v-if="loading"
    text-align="center"
  />
  
  <div 
    v-if="!loading"
    class="row"
  >
    <div
      v-for="user in filteredUsers" 
      :key="user.id" 
      class="col-6 col-sm-4 my-3"
    >
      <user-card
        :user="user"
        @deleted="markUserAsDeleted"
      />
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/UI/Dropdown.vue';
import { getAll as getAllUsers } from '@/api/providers/users';
import { 
  addUsersToRestrictedProject, 
  removeUsersFromRestrictedProject
} from '@/api/providers/projects';

import UserCard from './UserCard.vue';

import { toRaw } from 'vue';
import LoadingSpinner from '@/components/UI/LoadingSpinner.vue';

export default {
  components: {
    Dropdown,
    LoadingSpinner,
    UserCard
  },

  props: {
    userIds: { type: Array, required: true },
    showUpdate: { type: Boolean, default: true },
  },

  data() {
    return {
      userOptions: [],
      selectedUsers: [],
      loading: true
    };
  },

  computed: {
    filteredUsers() { return this.selectedUsers.filter(u => !u.isDeleted); },
  },

  async mounted() {
    await this.getUsers();
    this.initializeSelectedUsers();
    this.loading = false;
  },

  methods: {
    async getUsers() {
      try {
        let users = await getAllUsers();
        if (users == null) users = [];

        this.userOptions = users.map(u => {
          return {
            value: u.id,
            label: `${u.firstName ?? ''} ${u.insertion ?? ''} ${u.lastName ?? ''}`,
            image: u.profilePicture
          }
        });
      }
      catch {
        this.userOptions = [];
      }
    },

    initializeSelectedUsers() {
      const options = this.userOptions.filter(o => 
        (this.userIds ?? []).includes(Number(o.value))
      ).map(o => toRaw(o));

      for (const option of options) {
        this.selectedUsers.push({
          id: option.value,
          name: option.label,
          image: option.image
        });
      }
    },

    addUser(id) {
      if (this.selectedUsers.find(u => u.id === id) != null) return;

      let option = this.userOptions.find(o => o.value === id);
      if (option == null) return;

      this.selectedUsers.push({
        id: option.value,
        name: option.label,
        image: option.image,
        isNew: true
      });
    },

    async submit(projectId) {
      await this.submitAddedUsers(projectId);
      await this.submitRemovedUsers(projectId);
    },

    async submitAddedUsers(projectId) {
      const userIds = this.selectedUsers
          .filter(u => u.isNew && !u.isDeleted)
          .map(u => u.id);

      await addUsersToRestrictedProject(userIds, projectId);
    },

    async submitRemovedUsers(projectId) {
      let users = this.selectedUsers
          .filter(u => u.isDeleted && !u.isNew)
          .map(u => u.id);

      await removeUsersFromRestrictedProject(
        users,
        projectId
      );
    },

    markUserAsDeleted(id) {
      const userIndex = this.selectedUsers.findIndex(u => u.id === id);
      if (userIndex < 0) return;

      this.selectedUsers[userIndex] = { 
        ...this.selectedUsers[userIndex], 
        isDeleted: true
      };
    }
  },
}
</script>