import { get } from  '@/api/providers/organization-settings'

export function getDefaultAuthorId()
{
  return new Promise((resolve, reject) => {
    get()
      .then(settings => {
        if(settings.defaultAuthorId != undefined) resolve(settings.defaultAuthorId)

        reject()
      })
      .catch(() => reject())
  })
}