<template>
  <div class="row mt-4">
    <div class="col-12">
      <material-input
        id="title"
        v-model="titleHandler"
        is-required
        variant="static"
        label="Titel"
        placeholder="Vul een titel in"
      />
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <material-input
        id="title"
        v-model="subtitleHandler"
        is-required
        variant="static"
        label="Subtitel"
        placeholder="Vul een subtitel in"
      />
    </div>
  </div>

  <material-button
    v-if="showUpdate"
    class="float-end mt-6 mb-0"
    color="dark"
    variant="gradient"
    size="sm"
  >
    Update gegevens
  </material-button>
</template>

<script>
import { createVModelHandlers } from '@/helpers/vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';

export default {
  name: 'BasicInfo',

  components: {
    MaterialButton,
    MaterialInput
  },

  props: {
    subtitle: {
      type: String,
      required: true,
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  emits: [
    'update:description',
    'update:title',
    'update:subtitle'
  ],

  computed: {
    ...createVModelHandlers(
      'description',
      'title',
      'subtitle'
    )
  }
};
</script>